<template>
  <v-row>
    <v-col>
      <div class="outside-container">
        <video
          class=""
          id="video"
          ref="video"
          autoplay
          loop
          muted
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <source
            poster="https://res.cloudinary.com/jlr-contractors/image/upload/v1656420384/Heros/jlr-home-hero-poster_kyzp9j.webp"
            :src="require('../assets/jlr-background-hero-video.mp4')"
            type="video/mp4"
          />
        </video>
        <div class="video-overlay" v-if="$vuetify.breakpoint.mdAndUp">
          <div id="socials">
            <p class="ml-n5 body-1 white--text">Follow us</p>
            <div class="d-flex flex-column align-start mt-9">
              <v-icon
                v-for="social in $store.state.socialsContractors"
                :key="social.i"
                size="30"
                color="white"
                class="my-1"
                @click="openSocial(social.link)"
              >
                {{ social.icon }}
              </v-icon>
            </div>
          </div>
          <hero
            :title="hero.title"
            :titleColored="hero.titleColored"
            :subtitle="hero.subtitle"
          />
          <!-- <div id="gradient" /> -->
        </div>
        <v-img
          v-else
          rel="preload"
          src="https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_900/v1656507452/Heros/ExcellencePhoto-narrow-min_ljgdkj.webp"
          min-height="99vh"
          max-height="99vh"
          max-width="100vw"
          class="pb-2 pr-0"
          dark
        >
          <div id="socials" v-if="$vuetify.breakpoint.mdAndUp">
            <p class="ml-n5 body-1">Follow us</p>
            <div class="d-flex flex-column align-start mt-9">
              <v-icon
                v-for="social in $store.state.socials"
                :key="social.i"
                size="30"
                class="my-1"
                @click="openSocial(social.link)"
              >
                {{ social.icon }}
              </v-icon>
            </div>
          </div>
          <hero
            :title="hero.title"
            :titleColored="hero.titleColored"
            :subtitle="hero.subtitle"
          />
          <div id="gradient" />
        </v-img>
      </div>
      <v-row>
        <v-col class="py-0">
          <Banner
            :imageIndex="'woodwork'"
            imageType="jpg"
            imageHeight="400"
            :image="banner1.image"
            :heading="banner1.heading"
            :subheading="banner1.subheading"
            :description="banner1.description"
            :list="banner1.list"
            :btn="banner1.btn"
            :headHTML="banner1.headHTML"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0">
          <Quote
            :image="quote.image"
            imageIndex="home_1"
            imageType="jpg"
            :title="quote.title"
            :height="quote.height"
            :titleHTML="quote.titleHTML"
          />
        </v-col>
      </v-row>
      <v-row v-for="banner in banners" :key="banner.i">
        <v-col class="pr-0">
          <Banner
            :imageIndex="'woodwork'"
            :image="banner.image"
            imageType="jpg"
            imageHeight="400"
            :heading="banner.heading"
            :subheading="banner.subheading"
            :description="banner.description"
            :list="banner.list"
            :btn="banner.btn"
            :btn2="banner.btn2"
            :no-space="true"
            :reverse="banner.reverse"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0 mb-0 text-center">
          <h2 class="white--text display-2">View Our Work</h2>
        </v-col>
      </v-row>
      <v-row
        class="mx-auto"
        :class="$vuetify.breakpoint.mdAndUp ? 'py-10' : 'mt-16'"
      >
        <v-col
          class="py-0"
          v-for="(item, i) in gridItems"
          :key="item.i"
          cols="12"
          sm="6"
          md="3"
        >
          <GridItem
            page="home"
            :index="i"
            :gridItem="item"
            :image="item.image"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <Companies :slides="slides" />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0">
          <ImageRow
            image="https://res.cloudinary.com/jlr-contractors/image/upload/v1655727656/Banners/jlr_quote_img_home_2_sojgh8.webp"
            :fix="true"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Hero from "@/components/Page/Hero";
import Banner from "@/components/Page/Banner";
import Quote from "@/components/Page/Quote";
import Companies from "@/components/Page/Companies";
import GridItem from "../components/About/GridItem.vue";
import ImageRow from "../components/Page/ImageRow.vue";
export default {
  name: "Home",

  components: {
    Hero,
    Banner,
    Quote,
    Companies,
    GridItem,
    ImageRow,
  },

  data() {
    return {
      hero: {
        title: "Proudly serving for over",
        titleColored: "100 years",
      },
      banner1: {
        image:
          "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_1000/v1655728702/Banners/ExcellencePhoto_gpcqji.webp",
        heading: `There is no substitute for <span class="primary--text">Excellence</span>`,
        headHTML: true,
        description: [
          `For over 100 years J. Lyne Roberts & Sons (now JLR Contractors) has continued to build and remodel
         homes, apartments, commercial buildings, city parks playgrounds and public works projects with the same quality 
         and integrity that lies at the heart and soul of this family run business.`,
          `Our motto has always been: "<span class="primary--text">there is no substitute for excellence</span>" and we feel
         that everyone deserves a quality project done on time, safely and within budget.`,
        ],
        list: [
          { title: "Residential / Remodel", to: "/our-work/residential" },
          { title: "Commercial / Remodel", to: "our-work/commercial" },
          { title: "Public Works", to: "our-work/public-works" },
          { title: "Architectural Woodwork", to: "jlr-woodwork" },
        ],
        btn: {
          text: "Contact Us",
          to: "/contact",
        },
      },
      banners: [
        {
          heading: "Architectural Woodwork",
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/v1655817253/Banners/DSC02066-HDR_rulyop.webp",
          description: [
            `<span class="primary--text">The Passion, Possibiliies and Perfection of Woodworking.</span>`,
            `The woodwork team has engineered, manufactured and installed every type of architectural woodwork
            for over 37 years, leading the industry by providing the latest wood grains to customers while producing
            a world-class product.`,
            `With our 40,000 square foot woodworking facility, located in Provo, UT, we are able to spend the necessary time
            in the early stages to minimize the cost and create a product that matches the architects/designers conceptual
            drawings while our three phases of <span class="primary--text">millwork, assembly, and finish</span>, are aided by the 
            latest in technology. The result? A process that enables perfection; expertise that produces world-class woodwork.`,
          ],
          btn: {
            text: "Contact Us",
            to: "/contact",
          },
          btn2: {
            text: "Woodwork Site",
            to: "/jlr-woodwork",
          },
          reverse: true,
        },
      ],
      gridItems: [
        {
          title: "RESIDENTIAL / REMODEL",
          link: "/our-work/residential",
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_600/v1655728702/Banners/ExcellencePhoto_gpcqji.webp",
          index: "Residential",
        },
        {
          title: "COMMERCIAL / REMODEL",
          link: "/our-work/commercial",
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_700/v1655727742/Heros/Hero_commercial_xq1mff.webp",
          index: "Commercial",
        },
        {
          title: "PUBLIC WORKS / INDUSTRIAL",
          link: "/our-work/public-works",
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_700/v1655727695/Heros/PublicWorkds2_gtxgil.webp",
          index: "Residential",
        },
        {
          title: "ARCHITECTURAL WOODWORK",
          link: "/our-work/architectural-woodwork",
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_700/v1655727696/Heros/Hero_architectural_woodwork_hfjnnz.webp",
          index: "Residential",
        },
      ],
      quote: {
        image:
          "https://res.cloudinary.com/jlr-contractors/image/upload/v1656417993/Banners/jlr_quote_img_home_1_gmt6zf.webp",
        title: `Unify <span class="primary--text mx-6">Strengthen</span> Grow`,
        titleHTML: true,
        height: "400",
      },
      slides: [
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1655813720/Logos/rock-canyon-bank-logo_ounc4q.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1655813720/Logos/provo-beach-logo_r0vhmk.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1655813720/Logos/provo-city-logo_vizbjs.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1655813719/Logos/called-to-surf-logo_hbqopq.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657372580/Logos/church-of-jesus-christ-of-latter-day-saints-logo_zvovvu.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657977196/Logos/Contractors/244618854_231761255642832_7716326384628836948_n-min_e3ooxo.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1659634210/Logos/Contractors/pngfind-min_kecwyg.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657977196/Logos/Contractors/Artboard-2-min_brdaiy.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657977195/Logos/Contractors/official_salem_rebrand_002-400x316-min_am1mu5.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657977195/Logos/Contractors/wvc-logo-color-square-600_orig-min_yc9v1j.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657977195/Logos/Contractors/wju-logo-min_ehfqbz.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657977195/Logos/Contractors/logo-alt-min_tnqnsc.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657977195/Logos/Contractors/logo-min_wf5lv4.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657977195/Logos/Contractors/Document_1_-min_felsyp.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657977195/Logos/Contractors/Document_3_-min_ksbtwq.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657977195/Logos/Contractors/logo_1_-min_xmgvnc.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657977195/Logos/Contractors/lindon_city_logo-min_vjwgsi.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657977195/Logos/Contractors/Document-min_fypond.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1657977195/Logos/Contractors/Document_2_-min_lsnduq.webp",
        },
        {
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_250/v1659624018/Logos/Contractors/color-horizontal-400x72-min_o253po.webp",
        },
      ],
    };
  },

  methods: {
    openSocial(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.outside-container {
  position: relative;
  height: 99vh;
}

#socials {
  position: absolute;
  top: 30%;
  left: 10px;
  z-index: 999;
  p {
    transform: rotate(90deg);
  }
}

video {
  min-width: 100vw;
  max-width: 100vw!important;
  min-height: 99vh;
  max-height: 99vh;
  object-fit: cover;
}
</style>
